html {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  background-color: #fff;
}

body > iframe {
  display: none;
}

/* *,
*::before,
*::after {
  box-sizing: unset !important;
} */

div#sideNavMenu *,
div#sideNavMenu *::before,
div#sideNavMenu *::after {
  box-sizing: unset !important;
}

svg,
svg ::before,
svg ::after {
  box-sizing: content-box !important;
}

img,
img ::before,
img ::after {
  box-sizing: content-box !important;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -64.5px;
  margin-top: -85.5px;
}

.ghost {
  animation: float 3s ease-out infinite;
}

@-webkit-keyframes float{
  50% {
     transform: translate(0, 20px);
  }
}
.shadow-frame {
  width: 130px;
  margin-top: 15px;
}

@-webkit-keyframes wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.notify-email-body p{
  margin: 0;
  font-size: 16px;
}
.notify-email-body a{
  padding: 10px;
}
.notify-email-body .ql-container.ql-snow, .ql-toolbar.ql-snow{
  border: none;
}
.notify-email-body .ql-toolbar.ql-snow {
  padding-top: 0;
  border: none;
}
.notify-email-body .ql-toolbar.ql-snow .ql-formats{
  border: 1px solid #8D8D8D;
  padding: 5px;
}

.LibrarianPanel-formControl-143 .MuiInput-underline::before {
    content: none !important
}
.MuiSelect-select:focus{
  background-color: #fff !important;
}
