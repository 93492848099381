.home-bg {
  background-image: url('//target.scene7.com/is/image/Target/assethub-homepage-image?cropN=0,0.2,0,0.8&sizeN=2000,2000&qlt=100&wid=1615');
  background-size: cover;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 600px) {
  .home-bg {
    background-image: url('//target.scene7.com/is/image/Target/assethub-homepage-image?cropN=0.3,0.2,0.4,0.8&wid=600&qlt=100');
  }
}
