@mixin fonts($weight, $size, $color, $line-height) {
  font-family: 'Helvetica for Target', sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: 0px;
  color: $color;
}

.ai-terms-popup {
  .MuiDialog-paper {
    max-width: 856px;
  }
  .MuiDialogContent-root {
    padding: 6px 24px;
  }

  ul {
    margin: 10px 0;

    span {
      @include fonts(400, 14px, #333, 21px);
    }
  }

  .title {
    @include fonts(700, 20px, #333, 20px);
  }

  .sub-title {
    @include fonts(700, 16px, #333, 24px);
  }

  .body {
    @include fonts(700, 14px, #000, 24px);
  }

  .MuiDialogActions-root {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    .MuiFormControlLabel-label {
      @include fonts(400, 14px, #333, 18px);
    }
    div {
      width: 180px;
      display: flex;
      gap: 30px;
      button {
        width: 70px;
        height: 32px;
        max-height: 32px;
        border-width: 1px;
        gap: 4px;
        border-radius: 8px;
        padding: 12px;
      }
      .accept {
        background-color: #3d70d6;
        border: 1px solid #2951a3;
        color: white;
      }
      .Mui-disabled {
        background: #e6e7ea;
        border: none;
        @include fonts(500, 14px, #8b8f98, 21px);
        text-transform: capitalize;
      }
    }
  }
}

//tabs
.ai-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #6153536b;
  .MuiTabs-root {
    width: 400px;
    button {
      color: #3d70d6 !important;
      text-transform: none;
    }
    .MuiTabs-indicator {
      background-color: #3d70d6 !important;
    }
  }
}
